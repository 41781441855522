import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {
  configure,
  hits,
  pagination,
  panel,
  refinementList,
  searchBox,
} from 'instantsearch.js/es/widgets';

const searchClient = algoliasearch(
  'QLNQ1F4GAD',
  '822eec5b502a6893a3c6039c4a72fa7f'
);

const search = instantsearch({
  indexName: 'cotation-ccam',
  searchClient,
  numberLocale: 'fr',

  searchFunction(helper) {
    // hide default search when search is empty
    const hitsContainer = document.querySelector('#hits');
    hitsContainer.style.display = helper.state.query === '' ? 'none' : '';

    const paginationContainer = document.querySelector('#pagination');
    paginationContainer.style.display = helper.state.query === '' ? 'none' : '';

    const categoryContainer = document.querySelector('#category-list');
    categoryContainer.style.display = helper.state.query === '' ? 'none' : '';

    // hide default search items when an actual search is made
    const defaultContainer = document.querySelector('#default-search-items');
    defaultContainer.style.display = helper.state.query !== '' ? 'none' : '';

    helper.search();
  }
});

search.addWidgets([
  searchBox({
    container: '#searchbox',
    placeholder: 'Recherchez une cotation, un acte, une consultation',
    poweredBy: true
  }),
  hits({
    container: '#hits',
    templates: {
      item: (hit, { html, components }) => html`
        <article>
          <h1><a href="src/web-cotations/${hit.code.replace('(', '').replace(')', '').toLowerCase()}.html">${components.Highlight({ hit, attribute: 'description' })}</a></h1>
          <p>
            <strong>
              <span class="ccam-code">${hit.code} <i class="fas fa-copy" style="color: #5072A7;"></i></span>
            </strong>
          </p>
          <p>${hit.price_sector_1}€</p>
          <p>${components.Highlight({ hit, attribute: 'sub_category_3' })}</p>
          <p>${components.Highlight({ hit, attribute: 'extra_description' })}</p>
        </article>
      `,
    },
  }),
  configure({
    hitsPerPage: 4,
  }),
  panel({
    templates: { header: 'Catégorie' },
  })(refinementList)({
    container: '#category-list',
    attribute: 'parent_category',
  }),
  pagination({
    container: '#pagination',
  }),
]);

search.on('render', () => {
  try {
    var codes = document.querySelectorAll('.ccam-code');
    for (let i = 0; i<codes.length; i++) {
      codes[i].addEventListener("click", function(){copyCCAM(codes[i].innerHTML).then(showNotif());}, false);
    }
  } catch(e) {
    console.error(e);
  }
})

search.start();